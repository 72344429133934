.employee {
    .create {
        width: 100%;
        max-width: 1100px;
        border-radius: 10px;
        overflow: auto;
        form .inputs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            > div {
                width: 48%;
            }
        }
    }
}
