.employee {
    .details {
        .card {
            padding-top: 0 !important;
        }
        .info {
            table {
                td {
                    .actions {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around !important;
                        max-width: 65px !important;
                        margin: 0 !important;
                    }
                    &.online {
                        color: #00b127;
                    }
                    &.expired {
                        color: #feee5b;
                    }
                }
            }
            .cols {
                display: flex;
                flex-direction: row;
                line-height: 22px;
                .col {
                    flex-grow: 1;
                    width: 32%;
                }
                .col:last-child {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        margin-top: 50px;
                        width: 200px;
                    }
                }
            }
            .header {
                padding: 20px 0;
                margin: 0;
                border: 0;
                display: block;
                h4 {
                    border: 0;
                    text-align: center;
                }
                .buttons {
                    justify-content: center;
                    button {
                        border: 0;
                        color: #fff;
                        background-color: var(--bg-dark);
                    }
                }
            }
        }
    }
}
