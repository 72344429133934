.employee {
    .details {
        .edit {
            width: 100%;
            max-width: 1100px;
            max-height: 720px;
            border-radius: 10px;
            overflow: auto;
            form .inputs {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                > div {
                    width: 32%;
                }
                .photo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 50px;

                    img {
                        margin-top: 20px;
                        width: 200px;
                    }

                    .upload-btn-wrapper {
                        position: relative;
                        margin-top: 20px;
                        width: 150px;
                    }
                    input {
                        opacity: 0;
                    }

                    .btn-upload, input {
                        position: absolute;
                        padding: 5px 10px;
                        width: 100%;
                        height: 35px;
                        text-align: center;
                        display: block;
                        border-radius: 5px;
                        text-decoration: none;
                        box-sizing: border-box;
                        border: 1px solid transparent;
                        cursor: pointer;
                        color: #fff;
                        background-color: var(--bg-dark);
                    }
                }
            }
        }
    }
}
