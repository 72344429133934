section.auth {
    background: #fff;
    >div .container {
        max-width: 400px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 15px;
    }
    header {
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        img {
            height: 100px;
            width: 100px;
            border-radius: 50px;
        }
    }
    h2 {
        margin-top: 20px;
        text-align: center;
    }
    footer {
        padding: 20px 0;
        margin: 10px auto;
        ul {
            list-style-type: none;
            list-style: none;
            border-bottom: 1px solid #bdc4c9;
            padding: 10px;
            max-width: 500px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            li {
                margin: 0 auto;
                a {
                    color: #005ff8;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }
        p {
            line-height: 40px;
            font-size: 13px;
            text-align: center;
            color: #868686;
        }
    }
}

@media (min-width: 700px) {
    section.auth {
        display: flex;
        flex-direction: row;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        >div {
            flex-basis: 50%;
            height: inherit;
        }
        >div:last-child {
            background: url(../../assets/images/auth-bg.jpg) bottom no-repeat;
            background-size: cover;
            position: relative;
            .shade {
                background-color: #48bb78;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                opacity: 0.2;
                width: 100%;
            }
        }
    }
}