.dashboard {
  .header {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      font-family: "DM Sans Bold";
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      color: #020e23;
    }
  }
  .charts {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    .payers, .paye {
      padding: 10px;
      box-sizing: border-box;
      width: 50%;
      h2 {
        margin-bottom: 10px;
      }
    }
  }
}
