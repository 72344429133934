.payer-report {
    .cards {
        ._card {
            border-radius: 10px;
            margin: 20px 10px;
            flex-basis: 33.3%;
            .content {
                padding: 20px 20px 10px 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                h3 {
                    font-family: "DM Sans Bold";
                    font-size: 50px;
                }
                p {
                    font-family: "DM Sans Regular";
                    font-size: 16px;
                }
            }
            .footer {
                padding: 10px;
                border-radius: 0 0 10px 10px;
                a {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-family: "DM Sans Bold";
                        font-size: 16px;
                        color: #fff;
                    }
                    img {
                        margin: 0 10px;
                    }
                }
            }
            &.green {
                background: #37ac83;
                box-shadow: 0px 20px 50px rgba(25, 104, 77, 0.3);
                .footer {
                    background: #229b71;
                }
            }
            &.red {
                background: #db6d5b;
                box-shadow: 0px 20px 50px rgba(104, 25, 25, 0.3);
                .footer {
                    background: #ce5642;
                }
            }
            &.yellow {
                background: #e89f3c;
                box-shadow: 0px 20px 50px rgba(104, 96, 25, 0.3);
                .footer {
                    background: #ba7f30;
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .cards {
            display: flex;
            flex-direction: row;
        }
    }
}
